
import {
  Component,
  Mixins,
  Ref,
} from 'vue-property-decorator';
import Logo from '@/assets/icons/logo.svg?inline';
import { TextField } from '@/components';
import { auth_store } from '@/store';
import { ValidateMixin, ErrorHandlereMixin } from '@/mixins';
import { VForm } from '@/types';
import { ResponseStatuses } from '@/enums';
import { RawLocation, Route } from 'vue-router';

// eslint-disable-next-line no-use-before-define
@Component<SignIn>({
  components: {
    Logo,
    TextField,
  },
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-use-before-define
    next((vm: SignIn) => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from;
    });
  },
})
export default class SignIn extends Mixins(ValidateMixin, ErrorHandlereMixin) {
  @Ref()
  readonly form!: VForm;

  form_valid = false;
  login = '';
  password = '';
  loading = false;
  is_auth_error = false;
  prevRoute!: Route;

  get auth_error() {
    return this.is_auth_error ? this.invalid_auth_message : null;
  }

  reset_auth_error() {
    this.is_auth_error = false;
  }

  async submit() {
    if (this.form_valid) {
      this.loading = true;

      try {
        await auth_store.actions.login({
          login: this.login,
          password: this.password,
        });

        if (this.prevRoute) {
          const route = this.prevRoute as RawLocation;

          this.$router.push(route);
        } else {
          this.$router.push({ name: 'worktables' });
        }
      } catch (error: any) {
        if (error.response.status === ResponseStatuses.Fail.UNAUTHORIZED) {
          this.is_auth_error = true;
        }

        this.handleServerError(error);
      }
      this.loading = false;
    }
  }
}
